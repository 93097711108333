import * as React from "react";
import {
  graphql,
  // Link
} from "gatsby";

// Layout
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import Matrix from "../../components/Matrix";
import { buildFluidImageData } from "@imgix/gatsby";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import {
  Box,
  Container,
  FillButton,
  Flex,
  Section,
  Title,
} from "tailwind-react-ui";

// Custom Components
import PageBanner from "../../components/PageBanner";
import HappySnappyLogo from "../../images/logos/happy-snappy-logo.png";

// GraphQL
export const query = graphql`
  query HappySnappyQuery {
    entry: craftHappySnappyHappySnappyEntry {
      id
      remoteId
      title
      typeHandle
      uri
      url
      featureImage {
        ...UploadAssetFragment
      }
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
    navigation: allCraftMainNavigationRelatedEntryEntry(
      filter: { relatedEntry: { elemMatch: { sectionId: { eq: 19 } } } }
    ) {
      group(field: relatedEntry___sectionId) {
        nodes {
          relatedEntry {
            title
            uri
          }
        }
      }
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const HappySnappy = ({ data: { entry, siteInfo, navigation } }) => {
  const subtitle = entry.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = entry.featureImage;
  const metaImage = featureImage || entry.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (
      entry.metaDescription ||
      entry.summary ||
      siteInfo.siteDescription
    ).replace(/(<([^>]+)>)/gi, ""),
    159
  );
  const metaTitle = truncateString(
    `${entry.metaTitle || entry.displayTitle || entry.title}`,
    53
  );
  const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null;
  const [showIframe, setShowIframe] = React.useState(false);

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  return (
    <Layout location={entry.url} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImageFormatted.src}
        url={entry.url}
        pathname={`/${entry.uri}`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      <Section p="0" className="relative">
        <PageBanner
          title={entry.title}
          subtitle={subtitle}
          image={featureImage}
        />
      </Section>

      <Section p="0" className="relative">
        <Container className="xl:w-4/5 pt-14 pb-8">
          <Title
            size={5}
            level={3}
            font="heading"
            className="font-heading text-primary text-center"
          >
            How was your experience at Table Mountain Aerial Cableway. Take our
            5-minute survey to help us improve
          </Title>
          {showIframe ? (
            <>
              {/* {document.body.style.overflow = 'hidden'} */}
              <Flex
                data-component-type="modal"
                className="justify-center items-stretch overflow-x-hidden overflow-y-hidden fixed inset-0 z-modal outline-none focus:outline-none"
              >
                <Box
                  className="relative w-8/9 md:w-2/3 lg:w-8/9 m-auto overflow-y-auto shadow-2xl rounded-lg"
                  style={{ maxWidth: "1170px" }}
                >
                  {/* Content */}
                  <FillButton
                    font="display"
                    text="secondary"
                    p="0"
                    className="absolute bg-transparent border border-transparent focus:outline-none focus:shadow-outline font-display inline-block leading-tight mb-2 ml-auto no-underline outline-none p-0 right-2 rounded select-none text-5xl text-secondary text-shadow z-50"
                    onClick={() => setShowIframe(false)}
                  >
                    ×
                  </FillButton>

                  <Flex className="border-0 rounded-lg relative w-full bg-blue outline-none focus:outline-none flex-col md:flex-row">
                    <Box className="relative p-7 flex-fill w-full bg-blue order-last md:order-first">
                      <iframe
                        src="https://flowcommunications.formstack.com/forms/post_experience_survey_form"
                        title="Post experience survey form"
                        height="600"
                        style={{ width: "100%" }}
                      ></iframe>
                    </Box>
                    )
                  </Flex>
                </Box>
              </Flex>
              <div className="opacity-80 fixed inset-0 z-high bg-primary"></div>
            </>
          ) : (
            // ): document.body.style.removeProperty('overflow') }
            ""
          )}
          <div className="flex justify-center mt-6">
            <a
              className="mt-3 sm:my-auto px-3 lg:pl-5 mx-auto"
              href="https://www.smileflingr.com/TBLM"
              aria-label="Check out Table Mountain Aerial Cableway on the Happy Snappy website!"
            >
              <img
                src={HappySnappyLogo}
                width="200"
                alt="Happy Snappy Logo"
                loading="lazy"
              />
            </a>
            <FillButton
              font="display"
              data-type="button"
              bg="secondary"
              bg-hocus="secondary"
              text="primary"
              text-hocus="primary"
              className=" w-25 transition duration-300 ease-in-out px-3 lg:px-8 py-3 rounded-full mx-auto mb-3 text-2xl"
              onClick={() => setShowIframe(true)}
            >
              Take the survey
            </FillButton>
          </div>
        </Container>
      </Section>

      {entry.bodyContent.length ? (
        <Section p="0" className="secondary-page relative">
          <Container className="xl:w-4/5 pt-14 pb-8">
            <Matrix blocks={entry.bodyContent} />
          </Container>
        </Section>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default HappySnappy;
